import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { t } from 'i18n'

import Aside from 'Components/Inbox/SideNav/NewContacts/Aside'
import ChannelMembers from './ChannelMembers'
import ChannelName from './ChannelName'

import {
  showChannelCarePlans,
  showChannelContacts,
  toggleChannelMember,
  toggleChannelMembers,
  nameChannel,
} from 'Actions/inbox/sideNav/channel'

import { showContacts } from 'Actions/inbox/sideNav/contacts'

import { startChannel } from 'Actions/inbox/conversation'

import {
  getNewContacts,
  getCarePlans,
  getSideNavOpenState,
  getNewContactsFetchingState,
  getSideNavMode,
  getSelectedChannelContacts,
  getChannel,
  getChannelNames,
  getAllSelected,
} from 'Selectors/inbox'

import { getCurrentUser } from 'Selectors/currentUser'

const i18nOpts = { scope: 'inbox.side_nav.channels' }

class ChannelContainer extends Component {
  constructor(props) {
    super(props)

    this.handleBack = this.handleBack.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.startChannel = this.startChannel.bind(this)
    this.confirmChannelMembers = this.confirmChannelMembers.bind(this)
    this.renderMembers = this.renderMembers.bind(this)
    this.renderName = this.renderName.bind(this)
    this.getPatientName = this.getPatientName.bind(this)

    this.patientName = null

    this.state = {
      channelName: props.title,
      errors: {},
    }
  }

  componentDidMount() {
    this.updateChannelNameWithPatient();
  }

  componentDidUpdate(prevProps) {
    // Only update when contacts change from empty to populated
    if (prevProps.contacts !== this.props.contacts && this.props.contacts && this.props.contacts.length) {
      this.updateChannelNameWithPatient();
    }
  }

  updateChannelNameWithPatient() {
    const { contacts, title } = this.props;

    if (contacts && contacts.length) {
      const foundPatientName = this.getPatientName(contacts);
      if (foundPatientName) {
        // Store the patient name so we keep it even if contacts array becomes empty
        this.patientName = foundPatientName;
        this.setState({
          channelName: `${foundPatientName}: ${title}`
        });
      }
    } else if (this.patientName) {
      // If we already know the patient name but contacts array is empty, still use it
      this.setState({
        channelName: `${this.patientName}: ${title}`
      });
    }
  }

  getPatientName(contacts = []) {
    const patient = contacts.find(member => member.role === 'patient');
    return patient ? patient.name : '';
  }

  handleBack() {
    if (this.props.carePlanCount === 1 && this.props.mode !== 'channelName') {
      this.props.back('singleCarePlan')
    } else {
      this.props.back(this.props.mode)
    }
  }

  handleClick(id) {
    this.props.toggleChannelMember(id)
  }

  handleChange({ target: { value } }) {
    this.setState({ channelName: value, errors: {} })
  }

  startChannel() {
    if (this.props.channelNames.includes(this.state.channelName)) {
      this.setState({ errors: { channelName: t('invalid_name', i18nOpts) } })
      return
    }

    this.props.startChannel({
      channelName: this.state.channelName,
      carePlanId: this.props.carePlanId,
      participants: this.props.selectedContacts,
      isPlaceUser: this.props.isPlaceUser,
    })
  }

  confirmChannelMembers() {
    // First check if we already have a stored patient name
    if (!this.patientName && this.props.contacts && this.props.contacts.length) {
      this.patientName = this.getPatientName(this.props.contacts);
    }

    const formattedChannelName = this.patientName ?
      `${this.patientName}: ${this.props.title}` : this.props.title;

    this.setState({ channelName: formattedChannelName })
    this.props.next(this.props.selectedContacts)
  }

  renderMembers() {
    const {
      contacts,
      selectedContacts,
      toggleChannelMember,
      toggleChannelMembers,
      title,
      selected,
    } = this.props
    return (
      <ChannelMembers
        contacts={contacts}
        onClick={this.confirmChannelMembers}
        onToggle={toggleChannelMember}
        selectAll={toggleChannelMembers}
        selectedContacts={selectedContacts}
        title={title}
        selected={selected}
      />
    )
  }

  renderName() {
    const { channelMembers, title } = this.props
    return (
      <ChannelName
        channelName={this.state.channelName}
        contacts={channelMembers}
        errors={this.state.errors}
        onChange={this.handleChange}
        onClick={this.startChannel}
        onToggle={this.handleClick}
        title={title}
      />
    )
  }

  render() {
    const { isFetching, isOpen, mode } = this.props

    return (
      <Aside
        onClick={this.handleBack}
        header={t(`${mode}.header`, i18nOpts)}
        isFetching={isFetching}
        isOpen={isOpen}
        render={mode === 'channelMembers' ? this.renderMembers : this.renderName}
      />
    )
  }
}

ChannelContainer.propTypes = {
  back: PropTypes.func.isRequired,
  carePlanCount: PropTypes.number.isRequired,
  carePlanId: PropTypes.string,
  channelMembers: PropTypes.array,
  channelNames: PropTypes.array,
  contacts: PropTypes.array,
  isFetching: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
  next: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  selectedContacts: PropTypes.array,
  startChannel: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  toggleChannelMember: PropTypes.func.isRequired,
  toggleChannelMembers: PropTypes.func.isRequired,
}

ChannelContainer.defaultProps = {
  carePlanId: '',
  channelMembers: [],
  channelNames: [],
  contacts: [],
  selected: true,
  selectedContacts: [],
}

const mapStateToProps = (state) => ({
  carePlanId: getChannel(state).carePlanId,
  carePlanCount: getCarePlans(state).length,
  channelMembers: getChannel(state).members,
  channelNames: getChannelNames(state),
  contacts: getNewContacts(state),
  isFetching: getNewContactsFetchingState(state),
  isOpen: getSideNavOpenState(state),
  mode: getSideNavMode(state),
  selectedContacts: getSelectedChannelContacts(state),
  selected: getAllSelected(state),
  title: getChannel(state).title,
  isPlaceUser: getCurrentUser(state).isPlaceUser,
})

const mapDispatchToProps = (dispatch) => ({
  back: (mode) => {
    if (mode === 'channelName') {
      dispatch(showChannelContacts())
    } else if (mode === 'singleCarePlan') {
      dispatch(showChannelCarePlans())
      dispatch(showContacts())
    } else {
      dispatch(showChannelCarePlans())
    }
  },
  next: (members) => dispatch(nameChannel(members)),
  startChannel: (conversation) => dispatch(startChannel(conversation)),
  toggleChannelMember: (member) => dispatch(toggleChannelMember(member)),
  toggleChannelMembers: (selected) => dispatch(toggleChannelMembers(selected)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ChannelContainer)
